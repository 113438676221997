import React from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function CheckboxField(props) {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formCheckboxField">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h5 className="mb-0">Checkbox</h5>
          <Button
            size="sm"
            variant="danger"
            onClick={props.onDelete}
          >
            Delete
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
}
