import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './header';
import Body from './body';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { pdfDocumentFetchRequested } from './redux/actions/pdfDocumentActions';
import {
  pdfOverlayCreateRequested,
  pdfOverlayDeleteRequested,
  pdfOverlayUpdateRequested,
  pdfOverlayActiveFieldChanged,
  pdfOverlaysFetchRequested
} from './redux/actions/pdfOverlayActions';
import { logRequest } from './redux/actions/logActions';
import FieldContext from './fieldContext';
import { contactsFetchRequested } from './redux/actions/contactActions';
import { signaturesFetchRequested } from './redux/actions/signatureActions';

export default function PdfOverlay(props) {
  const [activeAction, setActiveAction] = useState(null);
  const [initialsImage, setInitialsImage] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);

  const dispatch = useDispatch();

  const pdfDocumentReady = useSelector(
    (state) => state.pdfDocument.pdfDocumentReady
  );
  const signaturesReady = useSelector((state) => state.signature.ready);
  const pdfDocument = useSelector((state) => state.pdfDocument.pdfDocument);
  const contacts = useSelector((state) => state.contact.entities);
  const contactDetails = useSelector((state) => state.contact.contactDetails);
  const activePdfOverlay = useSelector(
    (state) => state.pdfOverlay.activePdfOverlay
  );
  const signature = useSelector((state) => state.signature.signature);
  const initials = useSelector((state) => state.signature.initials);

  useEffect(() => {
    dispatch(pdfDocumentFetchRequested(props.pdfDocumentId));
    dispatch(pdfOverlaysFetchRequested(props.pdfDocumentId));
    dispatch(contactsFetchRequested(props.flowId));
    dispatch(signaturesFetchRequested());
  }, [dispatch]);

  useEffect(() => {
    if (
      signature !== null &&
      signature !== undefined &&
      initials !== undefined &&
      initials !== null
    ) {
      let signatureImg = new Image();
      signatureImg.src = URL.createObjectURL(signature);

      let initialsImg = new Image();
      initialsImg.src = URL.createObjectURL(initials);

      setSignatureImage(signatureImg);
      setInitialsImage(initialsImg);
    }
  }, [signature, initials]);

  // TODO: Append all console.log important messages to use this method
  const log = (level, message, program) => {
    let user = JSON.parse(window.sessionStorage.getItem('user'));

    let logProgram =
      program !== undefined && program !== null ? program : 'PdfOverlay';
    let logMessage = '[' + logProgram + '] ' + message + ' user=' + user.id;

    let logObj = {
      level: level,
      message: logMessage
    };

    console.log(logMessage);

    dispatch(logRequest(logObj));
  };

  const createField = (type, fieldPosition) => {
    // for signature, initials, and date_signed choose the first contact
    let value = type === 'text' ? '' : null;
    let width = {
      signature: 85,
      initials: 35,
      date_signed: 85,
      text: 255,
      checkbox: 20,
      user_checkbox: 20
    };
    if (type === 'signature' || type === 'initials' || type === 'date_signed') {
      const signers = contacts.filter((contact) => contact.role === "signer");
      value = activePdfOverlay?.value || signers[0]?.id || 'user';
    } else if (type === 'user_checkbox') {
      const signers = contacts.filter((contact) => contact.role === "signer");
      value = activePdfOverlay?.value || 'user' || signers[0]?.id;
    }

    if (
      fieldPosition.x !== null &&
      fieldPosition.x !== undefined &&
      fieldPosition.y !== null &&
      fieldPosition.y !== undefined &&
      fieldPosition.page !== null &&
      fieldPosition.page !== undefined
    ) {
      let field = {
        field_type: type,
        x: fieldPosition.x,
        y: fieldPosition.y,
        width: width[type],
        height: type === 'text' ? 15 : 20,
        page: fieldPosition.page,
        value: value,
        pdf_document_id: props.pdfDocumentId
      };
      dispatch(
        pdfOverlayCreateRequested(
          props.pdfDocumentId,
          field,
          (pdfOverlayId) => {
            setActivePdfOverlay(pdfOverlayId);
          }
        )
      );
    } else {
      let message = 'FieldPosition not set, cannot create field: ' + type;
      log('error', message);
    }
  };

  const changeActiveAction = (action) => {
    if (activeAction === action) {
      setActiveAction(null);
      return;
    }
    setActiveAction(action);
  };

  const setActivePdfOverlay = (pdfOverlayId) => {
    dispatch(pdfOverlayActiveFieldChanged(pdfOverlayId));
  };

  const updateField = (params) => {
    console.log('Updating Field: ' + JSON.stringify(params));
    dispatch(
      pdfOverlayUpdateRequested(props.pdfDocumentId, params, () => {
        setActivePdfOverlay(params.id);
      })
    );
  };

  const onDelete = () => {
    if (activePdfOverlay) {
      dispatch(
        pdfOverlayDeleteRequested(pdfDocument.id, activePdfOverlay.id, () => {
          setActivePdfOverlay(null);
          setActiveAction(null);
        })
      );
    } else {
      console.log('No field selected to delete!');
    }
  };

  if (pdfDocumentReady && signaturesReady) {
    return (
      <Container style={{ position: 'relative', padding: 0 }}>
        <Row>
          <Col md={12}>
            <Header
              pdfDocumentId={pdfDocument.id}
              flowId={props.flowId}
              activeAction={activeAction}
              changeActiveAction={changeActiveAction}
            />
            <FieldContext
              activeField={activePdfOverlay}
              onDelete={onDelete}
              updateField={updateField}
              contacts={contacts}
              contactDetails={contactDetails}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Body
              pdfUrl={pdfDocument.pdf_url}
              pdfDocumentId={pdfDocument.id}
              flowId={props.flowId}
              changeActiveAction={changeActiveAction}
              activeAction={activeAction}
              createField={createField}
              setActiveField={setActivePdfOverlay}
              activeField={activePdfOverlay}
              updateField={updateField}
              contacts={contacts}
              contactDetails={contactDetails}
              signatureImage={signatureImage}
              initialsImage={initialsImage}
              onDelete={onDelete}
              log={log}
            />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <p>loading...</p>;
  }
}
