import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static values = {
    id: String
  };

  connect() {
    this.element.addEventListener('turbo:frame-load', () => {
      this.open();
    });
  }

  open() {
    const modalElement = document.getElementById('edit-contact-modal')
    if (modalElement) {
      const modal = Modal.getOrCreateInstance(modalElement);
      modal.show();
    }
  }

  close() {
    const modalElement = document.getElementById('edit-contact-modal')
    if (modalElement) {
      const modal = Modal.getInstance(modalElement);
      if (modal) {
        modal.hide();
      }
    }
  }
}