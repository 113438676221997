import React, { useEffect } from 'react';

import { Box, Button, Card, CircularProgress, Divider, Fade, Grid, LinearProgress, Link, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTransactionStore from '../../shared/stores/TransactionsStore';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import useComplianceReviewStore from '../../shared/stores/ComplianceReviewStore';
import TransactionTypeTag from '../../shared/components/TransactionTypeTag';
import Comments from '../components/Comments';
import useAlertStore from '../../shared/stores/AlertStore';
import TransactionStatusTag from '../../shared/components/TransactionStatusTag';
import RequiredDocuments from '../components/RequiredDocuments';
import History from '../components/History';
import PageLoader from '../../shared/components/PageLoader';
import { format } from 'date-fns';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Review() {
  const [ activeTab, setActiveTab ] = React.useState(0);
  const { setTransactions } = useTransactionStore();
  const { setAlertMessage } = useAlertStore();
  const [ analyzing, setAnalyzing ] = React.useState(false);
  const { documents, setDocuments, currentDocument, setCurrentDocument, updateDocument, selectedTransactions, setSelectedTransactions } = useComplianceReviewStore();
  const [ analysis, setAnalysis ] = React.useState(currentDocument?.analysis?.summary);

  const [searchParams] = useSearchParams();
  const searchParamTransactionIds = searchParams.get('transactionIds')?.split(',')

  const navigate = useNavigate();

  const handlePreviousDocument = () => {
    const currentIndex = documents.indexOf(currentDocument);
    if (currentIndex - 1 >= 0) {
      setCurrentDocument(documents[currentIndex - 1]);
    }
  }

  const handleBack = () => {
    if(searchParamTransactionIds?.length > 0) {
      window.location = `/transactions/${searchParamTransactionIds[0]}/documents`;
    } else {
      navigate('/v2/compliances');
    }
  }

  const handleNextDocument = () => {
    const remainingDocuments = documents.filter((document) => document.compliance_status === 'pending' && document.id !== currentDocument.id);
    const currentIndex = documents.indexOf(currentDocument);

    if (remainingDocuments.length === 0) {
      setSelectedTransactions([]);
      setTransactions([]);
      handleBack();
    }
    if (currentIndex + 1 < documents.length) {
      setCurrentDocument(documents[currentIndex + 1]);
    } else {
      setCurrentDocument(remainingDocuments[0]);
    }
  }

  const handleUpdate = (data) => {
    Request.patch(
      getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}`,
      { transaction_id: currentDocument.transaction.id, complianceable: data},
      (response) => {
        updateDocument(response);
      }
    )
  }

  const handleApprove = () => {
    handleUpdate({ compliance_status: 'approved' });
    handleNextDocument();
    setAlertMessage('Document approved!', 'success');
  }

  const handleDeny = () => {
    handleUpdate({ compliance_status: 'denied' });
    handleNextDocument();
    setAlertMessage('Document denied!', 'error');
  }

  const handleAnalyze = () => {
    setAnalyzing(true);
    Request.get(getApiUrl() + `/v2/compliances/complianceables/${currentDocument.id}/analyze_document`, (response) => {
      setAnalyzing(false);
      setAnalysis(response.analysis.summary);
    })
  }

  useEffect(() => {
    if(searchParamTransactionIds?.length > 0) {
      Request.get(getApiUrl() + `/v2/compliances/complianceables?transaction_ids=${searchParamTransactionIds}`, (response) => {
        setDocuments(response);
        setCurrentDocument(response[0]);
      })
    }
    else if(!selectedTransactions?.length > 0) {
      Request.get(getApiUrl() + `/v2/compliances/complianceables?complianceable_ids=${documents.map((t) => t.id)}`, (response) => {
        setDocuments(response);
        setCurrentDocument(response[0]);
      })
    } else {
      Request.get(getApiUrl() + `/v2/compliances/complianceables?transaction_ids=${selectedTransactions.map((t) => t.id)}`, (response) => {
        setDocuments(response);
        setCurrentDocument(response[0]);
      })
    }
    return () => {
      setDocuments([]);
      setCurrentDocument(null);
    }
  }, [])

  useEffect(() => {
    setAnalysis(currentDocument?.analysis?.summary);
  }, [currentDocument])

  if(documents.length === 0 || !currentDocument) {
    return <PageLoader />
  }

  const formattedDate = format(new Date(currentDocument.updated_at), 'MM/dd/yyyy h:mm a');

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Button onClick={handleBack}>Back</Button>
        <Typography variant="h5" fontWeight={510}>
          {documents.indexOf(currentDocument) + 1} of {documents.length} documents remaining
        </Typography>
      </Stack>
      <Stack>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <Stack direction="row">
              <TransactionTypeTag transactionType={currentDocument?.transaction?.transaction_type} />
            </Stack>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Stack mb={2} spacing={1} my="auto">
                <Stack direction="row" spacing={4}>
                  <Typography variant="h4">
                    {currentDocument?.transaction?.name}
                  </Typography>
                  <Typography variant="body1" sx={{ my: 'auto !important' }}>
                    <TransactionStatusTag hideLabel transactionStatus={currentDocument?.transaction?.transaction_status} />
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} my="auto">
                <Box my="auto">
                  <Button variant="contained" onClick={handleApprove}>
                    Approve
                  </Button>
                </Box>
                <Box my="auto">
                  <Button disabled={currentDocument?.compliance_status === 'denied'} variant="contained" color="error" onClick={handleDeny}>
                    Reject
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={4} display="flex" justifyContent="right">
            <Stack direction="row" justifyContent="right" spacing={2} mt="auto" mb={2}>
              <Button variant="outlined" onClick={handlePreviousDocument}>
                Previous
              </Button>
              <Button variant="outlined" onClick={handleNextDocument}>
                Next
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <Stack spacing={2}>
              <Stack>
                <Card
                  sx={{
                    p: 2
                  }}
                >
                  <Stack>
                    <Typography variant="h6">
                      {currentDocument.required_document ? currentDocument.required_document.name : currentDocument.name}
                    </Typography>
                    {currentDocument.required_document && currentDocument.required_document.name != currentDocument.name && (
                      <Typography variant="body1" color="textSecondary">
                        {currentDocument.name}
                      </Typography>
                    )}
                    <Typography variant="body1" color="textSecondary" fontStyle="italic">
                      Last updated: {formattedDate}
                    </Typography>
                  </Stack>
                  <Stack my={1}>
                    <Divider />
                  </Stack>
                  {analyzing ?
                    <Skeleton height="200px" />
                  :
                  <Fade in={true} timeout={500}>
                    <Stack spacing={1}>
                      <Typography>
                        {analysis &&
                          <Stack>
                            <div dangerouslySetInnerHTML={{ __html: analysis }} />
                          </Stack>
                        }
                      </Typography>
                      <Stack>
                        {currentDocument.can_be_analyzed && <Button size="small" variant="outlined" onClick={handleAnalyze}>
                          {analysis ? "Re-analyze" : "Analyze"} Document
                        </Button>}
                      </Stack>
                    </Stack>
                  </Fade>
                  }
                </Card>
              </Stack>
              <Stack>
                <iframe src={currentDocument.pdf_url} width="100%" height="800px" />
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack>
              <Card
                sx={{
                  p: 2
                }}
              >
                <Tabs
                  value={activeTab}
                  onChange={(event, newValue) => setActiveTab(newValue)}
                >
                  <Tab label="Comments" value={0} />
                  <Tab label="History" value={1} />
                  <Tab label="Document Checklist" value={2} />
                </Tabs>
                <CustomTabPanel value={activeTab} index={0}>
                  <Comments />
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                  <Stack>
                    <History />
                  </Stack>
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={2}>
                  <RequiredDocuments />
                </CustomTabPanel>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  )
}