import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { DebounceInput } from 'react-debounce-input';

export default function TextField(props) {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formTextField">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex flex-row gap-4">
            <h5 className="mb-2">Text</h5>
            <DebounceInput
              element="textarea"
              onChange={(e) => props.onChange(e.target.value)}
              debounceTimeout={500}
              value={props.value}
              style={{
                borderRadius: 5,
                fontSize: 14,
                borderColor: '#a6a8ab',
                paddingTop: 10,
                paddingBottom: 10,
              }}
              placeholder={'Type your text here'}
            />
          </div>
          <Button
            size="sm"
            variant="danger"
            style={{ marginLeft: 20 }}
            onClick={props.onDelete}
          >
            Delete
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
}
