import {
  Box,
  Button,
  Card,
  Chip,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageTitle from '../../shared/components/PageTitle';
import useContactsStore from '../../shared/stores/ContactsStore';
import Request from '../../shared/services/Request';
import { getApiUrl } from '../../shared/Network';
import OfferTable from '../../shared/components/OfferTable';
import { useNavigate } from 'react-router-dom';
import OfferPopover from '../../shared/components/OfferPopover';
import ContactCard from '../components/ContactCard';
import ContactTypeSelect from '../components/ContactTypeSelect';
import PageLoader from '../../shared/components/PageLoader';
import { useForm } from 'react-hook-form';
import { formatPhone } from '../../../utils/formatPhone';

export default function Contacts() {
  const { contacts, setContacts, updateContact, addContact, removeContact } = useContactsStore();
  const [ showNewContact, setShowNewContact ] = useState();
  const { setValue, register, handleSubmit, control, reset } = useForm();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [contactToDelete, setContactToDelete] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    Request.get(getApiUrl() + '/v2/contacts', (data) => {
      setContacts(data);
    })
  }, [])

  const handleContactTypeUpdate = (contactId, contactType) => {
    Request.patch(getApiUrl() + `/v2/contacts/${contactId}`, { contact_type: contactType }, (data) => {
      updateContact(data);
    })
  }

  const handleDeleteContact = () => {
    Request.delete(getApiUrl() + `/v2/contacts/${contactToDelete.id}`, () => {
      removeContact(contactToDelete);
      setShowDeleteDialog(false);
    });
  };

  const handlePhoneChange = (event) => {
    const formattedValue = formatPhone(event.target.value);
    setValue('phone', formattedValue);
  };

  const onSubmit = (values) => {
    Request.post(
      getApiUrl() + '/v2/contacts',
      values,
      (data) => {
        addContact(data);
        setShowNewContact(false);
        reset();
        setFormErrors({});
      },
      (errorResponse) => {
        if (errorResponse?.response?.status === 422) {
          setFormErrors(errorResponse.response.data.errors);
        }
      }
    );
  }

  if(!contacts) {
    return <PageLoader />
  }

  return (
    <Stack spacing={1} mb={10}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <PageTitle title="Contacts" subtitle="Manage your contacts and clients" />
        <Stack>
          <Button onClick={() => window.location = "/contacts"}>View legacy contacts feature</Button>
        </Stack>
      </Stack>
      <Stack>
        <Button variant="contained" onClick={() => setShowNewContact(true)}>Add Contact</Button>
      </Stack>
      {contacts.length > 0 && <Stack>
        <Card>
          <Box>
            <OfferTable
              rows={contacts || []}
              defaultRowsPerPage={10}
              title="Contacts"
              defaultOrderBy="full_name"
              // onRowClick={(contact) => navigate(`/v2/contacts/${contact.id}`)}
              headers={[
                { key: 'full_name', label: "Name", customValue: (row) => (
                  <OfferPopover
                    anchor={<Button>
                      <Stack direction="row">
                        <Stack textAlign="left" my="auto !important">
                          <Typography variant="body1">
                            {row.full_name}
                          </Typography>
                          <Typography variant="body2">
                            {row.phone}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Button>}
                    onHover
                    padding={1}
                  >
                    <ContactCard contact={row} />
                  </OfferPopover>
                ) },
                { key: 'groups', label: "Groups", customValue: (row) => (
                  <Stack direction="row" spacing={0.5}>
                    {(row.contact_groups || []).map((group) => (
                      <Chip key={group.id} label={group.name} size="small"/>
                    ))}
                  </Stack>
                )},
                { key: 'email', label: "Email" },
                { key: 'contactType', label: "Type", customValue: (row) => (
                  <Stack>
                    <ContactTypeSelect value={row.contact_type} onChange={(contactType) => handleContactTypeUpdate(row.id, contactType)} />
                  </Stack>
                )},
                {
                  key: 'actions', label: "Actions", customValue: (row) => (
                    <Stack direction="row" spacing={1}>
                      <Button variant="contained" size="small" onClick={() => navigate(`/v2/contacts/${row.id}`)}>View</Button>
                      <Button
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => {
                          setContactToDelete(row);
                          setShowDeleteDialog(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Stack>
                  )
                }
              ]}
            />
          </Box>
        </Card>
      </Stack>}
      <Dialog open={showNewContact} onClose={() => setShowNewContact(false)}>
        <Stack p={2} width="350px">
          <Stack>
            <Typography variant="h5">Add Contact</Typography>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Stack>
                <TextField size="small" label="First Name" {...register('first_name')} />
              </Stack>
              <Stack>
                <TextField size="small" label="Last Name" {...register('last_name')} />
              </Stack>
              <Stack>
                <TextField
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  size="small"
                  label="Email"
                  {...register('email')}
                  type="email"
                />
              </Stack>
              <Stack>
                <TextField size="small" label="Phone Number"  {...register('phone', { onChange: handlePhoneChange })} />
              </Stack>
              <Stack>
                <TextField size="small" label="Company" {...register('company')} />
              </Stack>
              <Stack>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Dialog>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Delete Contact</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the contact "{contactToDelete?.full_name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button variant="contained" color="error" onClick={handleDeleteContact}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}