import { Box, Button, Card, Stack, Typography,Divider, Dialog, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React, { useEffect } from 'react';
import useEnsureAuth from '../../shared/hooks/ensureAuth';
import useClientAppStore from '../../shared/stores/ClientAppStore';
import Request from '../../shared/services/Request';
import { formatDateTime } from '../../shared/services/dates';
import { useNavigate } from 'react-router-dom';
import TransactionStatusTag from '../../shared/components/TransactionStatusTag';

export default function Home() {
  const { user } = useEnsureAuth();
  const [ viewDocument, setViewDocument ] = React.useState(null);
  const navigate = useNavigate();

  const { currentTransaction, setDocuments, documents, setCurrentDocument} = useClientAppStore();

  useEffect(() => {
    Request.get(`/v2/client_apps/transactions/${currentTransaction?.id}/documents`, (data) => {
      setDocuments(data);
      console.log(data);
    })
  }, [currentTransaction?.id])

  const clientFillableDocuments = documents.filter((document) => document.fillable_by_client);
  const clientNonFillableDocuments = documents.filter((document) => !document.fillable_by_client);

  return (
    <Stack justifyContent="space-between" height='80vh'>
      <Stack spacing={1}>
        <Stack>
          <Typography variant="h2">Hi, {user.full_name} </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5">This dashboard will keep you informed and let you know what you need to do next</Typography>
        </Stack>
      </Stack>
      <Card>
        <Stack spacing={2} py={4} px={{ xs: 2, sm: 8 }}>
          <Stack>
            <Typography variant="h6" fontWeight="bold">Documents you need to fill out</Typography>
          </Stack>
          <Stack spacing={2}>
            {
              clientFillableDocuments.map((document) => (
                <Stack key={document.id} direction="row" justifyContent="space-between" spacing={2}>
                  <Stack>
                    <Typography variant="h5">{document.name}</Typography>
                    {document.created_at && <Typography variant="body1">Sent to you on {formatDateTime(document.created_at)}</Typography>}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    {document.document_status === "finished" &&
                      <Stack
                        bgcolor={'success.light'}
                        borderRadius="3px"
                        px={0.5}
                        my="auto !important"
                        height="25px"
                      >
                        <Typography sx={{ my: 'auto !important' }} variant="body1" color="white">
                          Submitted
                        </Typography>
                      </Stack>
                    }
                    {document.fillable_by_client && <Box my="auto !important">
                      <Button onClick={() => {
                        setCurrentDocument(document);
                        const url = (document.document_status === "finished") ? `documents/${document.id}/review` : `documents/${document.id}`;
                        navigate(url);
                      }} variant="contained">
                        Open
                      </Button>
                    </Box>}
                  </Stack>
                </Stack>
              ))
            }
          </Stack>
          <Divider />
          {clientNonFillableDocuments.length > 0 && <Accordion>
            <AccordionSummary>
              <Stack>
                <Typography variant="h6" fontWeight="bold">Additional documents sent to you by your agent ({clientNonFillableDocuments.length})</Typography>
                <Typography variant="body1">If required, you will receive an email to sign these documents once you've filled out any documents above.</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                {
                  clientNonFillableDocuments.map((document) => (
                    <Stack key={document.id} direction="row" justifyContent="space-between" spacing={2}>
                      <Stack>
                        <Typography variant="h5">{document.name}</Typography>
                        {document.created_at && <Typography variant="body1">Sent to you on {formatDateTime(document.created_at)}</Typography>}
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        {document.document_status === "finished" &&
                          <Stack
                            bgcolor={'success.light'}
                            borderRadius="3px"
                            px={0.5}
                            my="auto !important"
                            height="25px"
                          >
                            <Typography sx={{ my: 'auto !important' }} variant="body1" color="white">
                              Submitted
                            </Typography>
                          </Stack>
                        }
                        <Box my="auto !important">
                          <Button onClick={() => setViewDocument(document)} variant="outlined">
                            View
                          </Button>
                        </Box>
                      </Stack>
                    </Stack>
                  ))
                }
              </Stack>
            </AccordionDetails>
          </Accordion>}
        </Stack>
      </Card>
      <Stack>
        <Dialog
          open={viewDocument}
          onClose={() => setViewDocument(null)}
          maxWidth="lg"
        >
          <Stack p={2} width="65vw" height="80vh">
            <iframe height="100%" width="100%" src={viewDocument?.url} />
          </Stack>
        </Dialog>
      </Stack>
    </Stack>
  )
}