const MIN_WIDTH = 15;
const MIN_HEIGHT = 15;

export function onMouseDown(e, state) {
  let event = e;

  console.log("CLIKC")

  // Handle touch events
  if (event.type === 'touchstart') {
    event = event.changedTouches[0];
  }

  let mouse = getMouse(event, state.canvas);
  let pdfMouse = getPdfPageMouse(mouse, state.pages);

  console.log(
    'mouse : pdfMouse - ' + JSON.stringify(mouse) + JSON.stringify(pdfMouse)
  );

  let mx = mouse.x;
  let my = mouse.y;
  let py = pdfMouse.y;
  let page = pdfMouse.page;
  // console.log("mouse down " + mx + " " + my);

  let fieldData = getFieldFromCoord(state.fields, mx, py, page);

  const field = fieldData.field;
  const resize = fieldData.resize;

  console.log(field)
  // we are either dragging or resizing a field
  if (field != null) {
    // prevent scroll on mobile
    e.preventDefault();

    state.setActiveField(field.id);

    console.log('clicked field: ' + field.id + ' ' + mx + ' ' + my + ' ' + py);

    let dragOffX = mx - field.x;
    let dragOffY = my - toCanvasCoord(field.y, field.page, state.pages);
    // let dragOffY = my - field.y;

    return {
      dragOffX: dragOffX,
      dragOffY: dragOffY,
      dragging: !resize,
      resizing: resize,
      resizeCorner: fieldData.resizeCorner,
      field: { ...field },
      startCoord: [mouse.x, mouse.y]
    };
  } else if (state.activeAction) {
    console.log('Placing field at x: ' + mouse.x + ' y: ' + mouse.y);
  } else {
    // Nothing was clicked to remove active field if set
    state.setActiveField(null);
  }
}

export function onMouseUp(event, state) {
  // Handle touch events
  if (event.type === 'touchstart') {
    event = event.changedTouches[0];
  }

  let mouse = getMouse(event, state.canvas);
  let pdfMouse = getPdfPageMouse(mouse, state.pages);

  if (state.field !== null && state.field !== undefined) {
    if (
      state.resizing ||
      mouse.x !== state.startCoord[0] ||
      mouse.y !== state.startCoord[1]
    ) {
      state.updateField(state.field);
    }
  } else if (state.activeAction) {
    console.log('Placed ' + state.activeAction);
    state.createField(state.activeAction, pdfMouse);

  }

  return {
    dragging: false,
    resizing: false,
    resizeCorner: null,
    field: null,
    startCoord: null,
    actionMouse: null
  };
}

export function onRightClick(e, state) {
  e.preventDefault();
  state.changeActiveAction(null);
}

export function onMouseMove(e, state) {
  let event = e;

  // Handle touch events
  if (event.type === 'touchmove') {
    event = event.changedTouches[0];
  }

  let mouse = getMouse(event, state.canvas);
  let pdfMouse = getPdfPageMouse(mouse, state.pages);
  let px = pdfMouse.x;
  let py = pdfMouse.y;
  let mx = mouse.x;
  let my = mouse.y;
  let page = pdfMouse.page;

  // Modify this field via resize or move
  let updatedField = null;

  // Copy over field if it exists
  if (state.resizing || state.dragging) {
    updatedField = { ...state.field };
  }

  if (!state.resizing) {
    setCanvasCursor(
      state.canvas,
      px,
      py,
      page,
      state.activeField,
      state.activeAction,
      state.fields
    );
  }

  if (state.resizing) {
    console.log('RESIZING');
    e.preventDefault();

    let offsetX = 0;
    let offsetY = 0;

    switch (state.resizeCorner) {
      case 'top-left':
        offsetX = px - updatedField.x;
        offsetY = py - updatedField.y;
        break;
      case 'top-right':
        offsetX = px - (updatedField.x + updatedField.width);
        offsetY = py - updatedField.y;
        break;
      case 'bottom-right':
        offsetX = px - (updatedField.x + updatedField.width);
        offsetY = py - (updatedField.y + updatedField.height);
        break;
      case 'bottom-left':
        offsetX = px - updatedField.x;
        offsetY = py - (updatedField.y + updatedField.height);
        break;
    }

    const resizedRectangle = resizeRectangle(
      state.resizeCorner,
      offsetX,
      offsetY,
      [updatedField.x, updatedField.y, updatedField.width, updatedField.height]
    );

    updatedField.x = resizedRectangle[0];
    updatedField.y = resizedRectangle[1];
    updatedField.width = resizedRectangle[2];
    updatedField.height = resizedRectangle[3];

    // Update the rectangle or apply the changes to your UI
    console.log('Resized Rectangle:', resizedRectangle);
  }

  // if dragging
  if (state.dragging) {
    console.log('DRAGGING');
    // prevent scroll on mobile
    e.preventDefault();

    updatedField.x = mx - state.dragOffX;
    updatedField.page = page;

    updatedField.y = py - state.dragOffY;

    // console.log(JSON.stringify(mouse));
    // console.log(JSON.stringify(pdfMouse));
    // console.log("dragOffY: " + state.dragOffY);
    // console.log("dragOffX: " + state.dragOffX);
    // console.log("MX: " + mx + " MY: " + my);
    // console.log("page: " + updatedField.page + " my: " + my + " dragOffY: " + state.dragOffY + "PageYZero: " + pageYZero + " PageHeight: " + pageHeight);
    // console.log(JSON.stringify(updatedField));
  }
  if (updatedField !== null && updatedField !== undefined) {
    let fields = {};
    for (const [id, field] of Object.entries(state.fields)) {
      if (id == updatedField.id) {
        fields[id] = updatedField;
      } else {
        fields[id] = { ...field };
      }
    }

    return {
      fields: fields,
      field: updatedField
    };
  }
  if (state.activeAction) {
    // console.log('changing action x,y: ' + JSON.stringify(mouse));
    return {
      actionMouse: mouse
    };
  }
}

export function onKeyDown(event, state) {
  if (state.activeField !== null && state.activeField !== undefined) {
    let updatedField = { ...state.activeField };
    switch (event.key) {
      case 'ArrowUp':
        console.log('Up arrow key pressed');
        if (updatedField.y > 0) updatedField.y -= 1;
        break;
      case 'ArrowDown':
        console.log('Down arrow key pressed');
        if (updatedField.y < state.pages[state.activeField.page - 1])
          updatedField.y += 1;
        break;
      case 'ArrowLeft':
        console.log('Left arrow key pressed');
        if (updatedField.x > 0) updatedField.x -= 1;
        break;
      case 'ArrowRight':
        console.log('Right arrow key pressed');
        if (updatedField.x < state.width) updatedField.x += 1;
        break;
      case 'Backspace':
        console.log('Backspace key pressed');
        state.onDelete();
        break;
    }

    // Save if there was a change
    if (
      updatedField.x !== state.activeField.x ||
      updatedField.y !== state.activeField.y
    ) {
      let fields = {};
      for (const [id, field] of Object.entries(state.fields)) {
        if (id == updatedField.id) {
          fields[id] = updatedField;
        } else {
          fields[id] = { ...field };
        }
      }

      state.updateField(updatedField);

      return {
        activeField: updatedField,
        fields: fields
      };
    } else {
      return {
        activeField: null,
        fields: null
      };
    }
  }
}

function getMouse(e, canvas) {
  // TODO: Compute the total offset, do I need this?
  // if (element.offsetParent !== undefined) {
  //   do {
  //     offsetX += element.offsetLeft;
  //     offsetY += element.offsetTop;
  //   } while ((element = element.offsetParent));
  // }
  //
  // // Add padding and border style widths to offset
  // // Also add the <html> offsets in case there's a position:fixed bar
  // offsetX += 0; //this.stylePaddingLeft + this.styleBorderLeft + this.htmlLeft;
  // offsetY += 0; //this.stylePaddingTop + this.styleBorderTop + this.htmlTop;
  //
  // mx = e.pageX - offsetX;
  // my = e.pageY - offsetY;

  const canvasRect = canvas.getBoundingClientRect();
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  const x = e.clientX - canvasRect.left + scrollX;
  const y = e.clientY - canvasRect.top + scrollY;

  // We return a simple javascript object (a hash) with x and y defined
  return { x: x, y: y };
}

// This will adjust the x and y coord based on each PDF Page
// We still use a JS coord system where the origin 0,0 is the top left
// This will return an x, y, and a page index to which this mouse belongs
function getPdfPageMouse(mouse, pages) {
  let pdfMouse = { x: mouse.x, y: null, page: null };

  let pageYZero = null;

  if (pages === null || pages === undefined) {
    console.log('Issue calculcating PDF mouse: ' + JSON.stringify(mouse));
    return null;
  }

  for (let i = 0; i < pages.length; i++) {
    if (mouse.y <= pages[i]) {
      pdfMouse.page = i + 1;
      pageYZero = i === 0 ? 0 : pages[i - 1];
      break;
    }
  }

  pdfMouse.y = mouse.y - pageYZero;
  return pdfMouse;
}

export function drawFields(state) {
  let ctx = state.canvas.getContext('2d');
  ctx.clearRect(0, 0, state.canvas.width, state.canvas.height);

  // If there is an active Action then draw the related image
  if (state.actionMouse && state.activeAction) {

    let width, height;

    switch (state.activeAction) {
      case 'signature':
        width = 80;
        height = 24;
        break;
      case 'initials':
        width = 42;
        height = 24;
        break;
      case 'text':
        width = 100;
        height = 24;
        break;
      case 'checkbox':
        width = 24;
        height = 24;
        break;
      case 'user_checkbox':
        width = 24;
        height = 24;
        break;
      case 'date_signed':
        width = 80;
        height = 24;
        break;
    }


    ctx.drawImage(
      state.actionImage(state.activeAction),
      state.actionMouse.x,
      state.actionMouse.y,
      width,
      height
    );
  }

  let changedFields = {};

  for (const [id, field] of Object.entries(state.fields)) {
    // Convert the yCoord to the canvas y coordinate based on its page
    let coords = [
      field.x,
      toCanvasCoord(field.y, field.page, state.pages),
      field.width,
      field.height
    ];

    // draw rectangle box for the element
    let contactDetails = state.contactDetails;
    if (
      contactDetails !== undefined &&
      contactDetails !== null &&
      contactDetails[field.value] !== undefined &&
      contactDetails[field.value] !== null
    ) {
      // ctx.fillStyle = contactDetails[field.value].color.replace(')', ',.6)');
      ctx.fillStyle = 'rgb(178, 178, 70,.6)';
    } else {
      ctx.fillStyle = 'rgb(70, 178, 117,.6)';
    }

    ctx.fillRect(coords[0], coords[1], coords[2], coords[3]);
    //console.log(coords[0] + ", " + (coords[1] - coords[3]) +", "+coords[2]+", "+coords[3]);

    if (
      state.activeField !== null &&
      state.activeField !== undefined &&
      state.activeField.id === field.id
    ) {
      ctx.strokeStyle = 'rgb(70, 178, 117)';
      ctx.lineWidth = 3;
      ctx.strokeRect(coords[0], coords[1], coords[2], coords[3]);

      // draw resize corners
      if (
        state.activeField.field_type === 'text' ||
        (state.activeField.field_type === 'initials' &&
          state.activeField.value === 'user') ||
        (state.activeField.field_type === 'signature' &&
          state.activeField.value === 'user')
      ) {
        // top-left corner resize circle
        ctx.beginPath();
        ctx.fillStyle = 'rgb(70, 178, 117)';
        ctx.arc(coords[0], coords[1], 3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.arc(coords[0], coords[1], 2, 0, 2 * Math.PI);
        ctx.fill();

        // top-right corner resize circle
        ctx.beginPath();
        ctx.fillStyle = 'rgb(70, 178, 117)';
        ctx.arc(coords[0] + coords[2], coords[1], 3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.arc(coords[0] + coords[2], coords[1], 2, 0, 2 * Math.PI);
        ctx.fill();

        // bottom-right corner resize circle
        ctx.beginPath();
        ctx.fillStyle = 'rgb(70, 178, 117)';
        ctx.arc(
          coords[0] + coords[2],
          coords[1] + coords[3],
          3,
          0,
          2 * Math.PI
        );
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.arc(
          coords[0] + coords[2],
          coords[1] + coords[3],
          2,
          0,
          2 * Math.PI
        );
        ctx.fill();

        // bottom-left corner resize circle
        ctx.beginPath();
        ctx.fillStyle = 'rgb(70, 178, 117)';
        ctx.arc(coords[0], coords[1] + coords[3], 3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.arc(coords[0], coords[1] + coords[3], 2, 0, 2 * Math.PI);
        ctx.fill();
      }
    }

    //draw field type text
    let fieldText = '';

    switch (field.field_type) {
      case 'text':
        if (field.value) {
          fieldText = field.value;
        } else {
          fieldText = 'Add Text...';
        }
        break;
      case 'signature':
        fieldText = 'Sign';
        break;
      case 'initials':
        fieldText = '';
        break;
      case 'date_signed':
        fieldText = 'mm/dd/yyyy';
        break;
      case 'checkbox':
        fieldText = 'X';
        break;
    }

    if (field.field_type === 'date_signed') {
      ctx.font = '10px Helvetica';
    } else {
      ctx.font = '14px Helvetica';
    }

    ctx.fillStyle = 'rgba(0,0,0)';

    if (field.field_type === 'text') {
      // ctx.font = `${resizeText(fieldText, coords[2], 10, ctx)} Arial`;
      ctx.textAlign = 'left';
      // ctx.fillText(fieldText, coords[0], coords[1] + coords[3] / 2, coords[2]);

      let newHeight = drawWrappedText(
        ctx,
        fieldText,
        coords[0],
        coords[1],
        coords[2],
        coords[3],
        14
      );

      // if (newHeight > coords[3]) {
      //   console.log(
      //     'Need to resize text and re-draw this field old: ' +
      //       coords[3] +
      //       ' new: ' +
      //       newHeight
      //   );
      //
      //   let updatedField = { ...field };
      //   updatedField.height = newHeight;
      //   changedFields[id] = updatedField;
      // }
    } else if (field.field_type === 'checkbox') {
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        fieldText,
        coords[0] + coords[2] / 2,
        coords[1] + coords[3] / 2
      );
    } else if (field.field_type === 'signature' && field.value === 'user') {
      ctx.drawImage(
        state.signatureImage,
        coords[0],
        coords[1],
        coords[2],
        coords[3]
      );
    } else if (field.field_type === 'initials' && field.value === 'user') {
      ctx.drawImage(
        state.initialsImage,
        coords[0],
        coords[1],
        coords[2],
        coords[3]
      );
    } else if (field.field_type === 'date_signed' && field.value === 'user') {
      let date = new Date();
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        date.toLocaleDateString(),
        coords[0],
        coords[1] + coords[3] / 2
      );
    } else if (field.field_type === 'user_checkbox' && field.value === 'user') {
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        'X',
        coords[0] + coords[2] / 2,
        coords[1] + coords[3] / 2
      );
    } else {
      const textWidth = ctx.measureText(fieldText).width;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.fillText(fieldText, coords[0] + 30, coords[1] + coords[3] / 2);

      // Draw the Initials circle
      if (
        field.value !== 'user' &&
        (contactDetails[field.value] === null ||
          contactDetails[field.value] === undefined)
      ) {
        console.log(
          'Error drawing ' +
            field.field_type +
            ': ' +
            field.id +
            ' No contact details found'
        );
      } else if (field.field_type === "user_checkbox") {
        ctx.beginPath();
        ctx.textAlign = 'center';
        ctx.arc(coords[0] + 12, coords[1] + coords[3] / 2, 8, 0, 2 * Math.PI);
        ctx.fillStyle = contactDetails[field.value].color;
        ctx.fill();
        ctx.fillStyle = 'rgb(0,0,0)';
        ctx.strokeStyle = 'rgb(0,0,0)';
        ctx.lineWidth = 1;
        ctx.stroke();
        ctx.font = '8px Helvetica';
        ctx.fillText(
          contactDetails[field.value].initials,
          coords[0] + 13,
          coords[1] + coords[3] / 2
        );
      } else {
        ctx.beginPath();
        ctx.textAlign = 'center';
        ctx.arc(coords[0] + 15, coords[1] + coords[3] / 2, 10, 0, 2 * Math.PI);
        ctx.fillStyle = contactDetails[field.value].color;
        ctx.fill();
        ctx.fillStyle = 'rgb(0,0,0)';
        ctx.strokeStyle = 'rgb(0,0,0)';
        ctx.lineWidth = 1;
        ctx.stroke();
        ctx.font = '10px Helvetica';
        ctx.fillText(
          contactDetails[field.value].initials,
          coords[0] + 15,
          coords[1] + coords[3] / 2
        );
      }

      if(field.field_type === 'initials') {
        ctx.closePath();
        ctx.stroke();
      } else if (field.field_type === 'date_signed') {
      //  Do Nothing
      } else if (field.field_type === 'user_checkbox') {
        // Draw the checkbox
        ctx.beginPath();
        ctx.rect(coords[0] + 2, coords[1] + coords[3] / 2 - 10, 20, 20);
        ctx.stroke();
      } else {
        // Draw the sign place icon
        // Draw a horizontal line
        ctx.beginPath();
        ctx.moveTo(coords[0] + coords[2] - 20, coords[1] + coords[3] - 2);
        ctx.lineTo(coords[0] + coords[2], coords[1] + coords[3] - 2);
        ctx.stroke();

        // Draw an upside-down caret
        const caretSize = 10;
        ctx.beginPath();
        ctx.moveTo(coords[0] + coords[2] - 10, coords[1] + coords[3] - 4); // Bottom point of caret
        ctx.lineTo(
          coords[0] + coords[2] - 10 - caretSize / 2,
          coords[1] + coords[3] - 4 - caretSize
        ); // Left point
        ctx.moveTo(coords[0] + coords[2] - 10, coords[1] + coords[3] - 4);
        ctx.lineTo(
          coords[0] + coords[2] - 10 + caretSize / 2,
          coords[1] + coords[3] - 4 - caretSize
        ); // Right point
        ctx.closePath();
        ctx.stroke();
      }
    }
  }

  // Handle when a text field needs to be resized due to the text
  // if (
  //   changedFields !== undefined &&
  //   changedFields !== null &&
  //   Object.keys(changedFields).length > 0
  // ) {
  //   for (const id in changedFields) {
  //     state.updateField(changedFields[id]);
  //   }
  //
  //   let updatedFields = { ...state.fields, ...changedFields };
  //   return { fields: updatedFields };
  // }
}

// Assumes a PDF x, y coordinate
function getFieldFromCoord(fields, x, y, page) {
  let fieldData = { field: null, resize: false, resizeCorner: null };
  for (const [id, field] of Object.entries(fields)) {
    if (field.page === page) {
      // Check if resize area of field was clicked, return field and resize=true
      let resizeCorner = isCornerClicked(x, y, [
        field.x,
        field.y,
        field.width,
        field.height
      ]);
      if (
        resizeCorner !== null &&
        resizeCorner !== undefined &&
        (field.field_type === 'text' ||
          (field.field_type === 'initials' && field.value === 'user') ||
          (field.field_type === 'signature' && field.value === 'user'))
      ) {
        console.log('Field Resize');
        fieldData.resize = true;
        fieldData.resizeCorner = resizeCorner;
        fieldData.field = field;
        return fieldData;
      }

      // Check if field was clicked, return resize=false
      if (
        fieldContainsCoord([field.x, field.y, field.width, field.height], x, y)
      ) {
        fieldData.field = field;
        console.log(fieldData)
        return fieldData;
      }
    }
  }
  return fieldData;
}

// Convert a yCoordinate to the combined canvas yCoordinate
function toCanvasCoord(my, page, pages) {
  let yZero = page === 1 ? 0 : pages[page - 2];

  return yZero + my;
}

function fieldResizeContainsCoord(c, x, y) {
  const radius = 3;
  const radiusX = c[0] + c[2];
  const radiusY = c[1];

  return (x - radiusX) ** 2 + (y - radiusY) ** 2 < radius ** 2;
}

// Also used to check if a user hovers over an active fields' resize areas
function isCornerClicked(clickX, clickY, rectangle) {
  const [rectX, rectY, rectWidth, rectHeight] = rectangle;

  // Define the size of the corner region
  const cornerSize = 10;

  // Check if the click is within the top-left corner
  if (
    clickX >= rectX &&
    clickX <= rectX + cornerSize &&
    clickY >= rectY &&
    clickY <= rectY + cornerSize
  ) {
    return 'top-left';
  }

  // Check if the click is within the top-right corner
  if (
    clickX >= rectX + rectWidth - cornerSize &&
    clickX <= rectX + rectWidth &&
    clickY >= rectY &&
    clickY <= rectY + cornerSize
  ) {
    return 'top-right';
  }

  // Check if the click is within the bottom-left corner
  if (
    clickX >= rectX &&
    clickX <= rectX + cornerSize &&
    clickY >= rectY + rectHeight - cornerSize &&
    clickY <= rectY + rectHeight
  ) {
    return 'bottom-left';
  }

  // Check if the click is within the bottom-right corner
  if (
    clickX >= rectX + rectWidth - cornerSize &&
    clickX <= rectX + rectWidth &&
    clickY >= rectY + rectHeight - cornerSize &&
    clickY <= rectY + rectHeight
  ) {
    return 'bottom-right';
  }

  // If the click is not within any corner, return null
  return null;
}

function fieldContainsCoord(c, x, y) {
  return c[0] <= x && c[0] + c[2] >= x && c[1] <= y && c[1] + c[3] >= y;
}

function resizeRectangle(corner, offsetX, offsetY, rectangle) {
  let [x, y, width, height] = rectangle;

  switch (corner) {
    case 'top-left':
      width -= offsetX;
      height -= offsetY;
      x += offsetX;
      y += offsetY;
      break;
    case 'top-right':
      width += offsetX;
      height -= offsetY;
      y += offsetY;
      break;
    case 'bottom-left':
      width -= offsetX;
      height += offsetY;
      x += offsetX;
      break;
    case 'bottom-right':
      width += offsetX;
      height += offsetY;
      break;
    default:
      return rectangle;
  }

  // Ensure minimum width and height
  width = Math.max(width, MIN_WIDTH);
  height = Math.max(height, MIN_HEIGHT);

  return [x, y, width, height];
}

function setCanvasCursor(
  canvas,
  x,
  y,
  page,
  activeField,
  activeAction,
  fields
) {
  // Change cursor on the active field if you hover over resize
  if (
    activeField !== null &&
    activeField !== undefined &&
    (activeField.field_type === 'text' ||
      (activeField.field_type === 'initials' && activeField.value === 'user') ||
      (activeField.field_type === 'signature' && activeField.value === 'user'))
  ) {
    // Check if the mouse is hovering near a corner
    let corner = isCornerClicked(x, y, [
      activeField.x,
      activeField.y,
      activeField.width,
      activeField.height
    ]);
    if (corner !== null && corner !== undefined) {
      switch (corner) {
        case 'top-left':
          canvas.style.cursor = 'nw-resize';
          return;
        case 'top-right':
          canvas.style.cursor = 'ne-resize';
          return;
        case 'bottom-right':
          canvas.style.cursor = 'se-resize';
          return;
        case 'bottom-left':
          canvas.style.cursor = 'sw-resize';
          return;
      }
    }
  }

  //Check if we are hovering over a field
  let { field, resize } = getFieldFromCoord(fields, x, y, page);
  if (!resize && field !== null && field !== undefined) {
    canvas.style.cursor = 'grab';
    return;
  } else {
    canvas.style.cursor = 'none';
  }

  if (canvas.style !== 'auto' && (activeAction === null || activeAction === undefined)) {
    canvas.style.cursor = 'auto';
  }
}

// TODO: we need to handle the 1 line case. Right now it's resizing to the number of lines + 1
function drawWrappedText(
  context,
  text,
  rectX,
  rectY,
  rectWidth,
  rectHeight,
  lineHeight
) {
  context.fillStyle = 'black'; // Set text color
  context.font = '14px Arial'; // Set font size and type

  const words = text.split(' ');
  let currentLine = '';
  let currentY = rectY + lineHeight;

  for (let i = 0; i < words.length; i++) {
    const characters = words[i].split('');

    for (let j = 0; j < characters.length; j++) {
      const testLine = currentLine + characters[j];
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > rectWidth && j > 0) {
        // Check if there's a space in the testLine
        const lastSpaceIndex = currentLine.lastIndexOf(' ');

        if (lastSpaceIndex !== -1) {
          // Wrap on the last space
          context.fillText(
            currentLine.substring(0, lastSpaceIndex),
            rectX,
            currentY
          );
          currentLine =
            currentLine.substring(lastSpaceIndex + 1) + characters[j];
        } else {
          // No space found, wrap mid-word
          context.fillText(currentLine, rectX, currentY);
          currentLine = characters[j];
        }

        currentY += lineHeight;

        // Adjust the height of the rectangle if necessary
        if (currentY > rectY + rectHeight) {
          rectHeight = currentY - rectY;
        }
      } else {
        currentLine = testLine;
      }
    }

    currentLine += ' ';

    // Check for width overflow after adding a space
    const metrics = context.measureText(currentLine);
    const testWidth = metrics.width;
    if (testWidth > rectWidth) {
      // Wrap on space if available, otherwise wrap mid-word
      const lastSpaceIndex = currentLine.lastIndexOf(' ');
      if (lastSpaceIndex !== -1) {
        context.fillText(
          currentLine.substring(0, lastSpaceIndex).trim(),
          rectX,
          currentY
        );
        currentLine = currentLine.substring(lastSpaceIndex + 1).trim();
      } else {
        context.fillText(currentLine.trim(), rectX, currentY);
        currentLine = '';
      }

      currentY += lineHeight;

      // Adjust the height of the rectangle if necessary
      if (currentY > rectY + rectHeight) {
        rectHeight = currentY - rectY;
      }
    }
  }

  // Draw the last line
  if (currentLine.trim() !== '') {
    context.fillText(currentLine.trim(), rectX, currentY);

    // Adjust the height of the rectangle if necessary for the last line
    if (currentY + lineHeight > rectY + rectHeight) {
      rectHeight = currentY + lineHeight - rectY;
    }
  }

  return rectHeight;
}
