import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  confirmDelete(event) {
    event.preventDefault();
    if (confirm('Are you sure you want to manually edit this document? You will not be able to edit with the form editor after this action is taken.')) {
      this.formTarget.submit();
    }
  }
}