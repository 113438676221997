import React, { useEffect } from 'react';
import { Card, CircularProgress, Grid, ThemeProvider, Dialog, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getApiUrl, sessionToTokenAuth } from '../shared/Network';
import useComplianceReviewStore from '../shared/stores/ComplianceReviewStore';
import Comments from '../compliance/components/Comments';
import theme from '../shared/theme';
import { Stack } from '@mui/material';
import useEnsureAuth from '../shared/hooks/ensureAuth';
import Request from '../shared/services/Request';
import { formatDateTime } from '../shared/services/dates';
import PageLoader from '../shared/components/PageLoader';

export default function App(props) {
  const { pdfUrl, text, bold, document, id } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clientQuestions, setClientQuestions] = React.useState([]);
  const { currentDocument, setCurrentDocument } = useComplianceReviewStore();

  const authLoaded = useEnsureAuth();

  if(!authLoaded) {
    return (
      <CircularProgress />
    )
  }

  const handleLinkClick = (e) => {
    console.log("Clickd")
    setOpen(true);
    if(!pdfUrl) {
      setLoading(true)
      e.preventDefault();
      Request.get(getApiUrl() + '/v2/compliances/complianceables/' + id, (data) => {
        setCurrentDocument(data);
        setClientQuestions(data.client_questions);
        setLoading(false);
      });
    }
  };

  const handleOnCloseModal = (e) => {
    console.log("CLosing")
    e.preventDefault();
    setCurrentDocument(null);
    setOpen(false);
  };

  return (
    <ThemeProvider key={text} theme={theme}>
      <span key={text + Math.random()} className="text-break">
        <Typography
          key={text}
          fontSize='0.875rem'
          fontWeight={300}
          onClick={handleLinkClick}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: 'primary.main',
            }
          }}
        >
          {bold ? <b>{text}</b> : <span>{text}</span>}
        </Typography>
      </span>
      <Dialog
        key={text}
        open={open}
        onClose={handleOnCloseModal}
        maxWidth={false}
      >
        <Grid container bgcolor='background.default' width='1200px'>
          <Grid item sm={7}>
            {currentDocument || pdfUrl ? <div style={{ width: '700px', height: '85vh' }} className="p-2">
              <iframe src={pdfUrl || currentDocument.pdf_url} style={{ border: 0, height: '100%', width: '100%' }} />
            </div> :
            <PageLoader />
          }
          </Grid>
          <Grid item sm={5} pt={1} pr={2}>
            <Stack direction="row" justifyContent="right">
              <IconButton onClick={handleOnCloseModal} size="large">
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack>
              <Typography variant="h4">
                Conversations
              </Typography>
              {clientQuestions?.length > 0 && <Stack mb={5}>
                <Typography variant="h6">Client Questions ({clientQuestions?.length})</Typography>
                <Stack spacing={2}>
                  {clientQuestions?.map((question) => {
                    return (
                      <Card>
                        <Stack p={1}>
                          <Typography variant="body2">{question.section}</Typography>
                          <Typography variant="caption">{question.question}</Typography>
                          <Stack pl={2} pt={2}>
                            <Stack
                              bgcolor={'lightgrey'}
                              p={2}
                              borderRadius="8px"
                              mb={2}
                            >
                              <Typography variant="body1">{question.client_question}</Typography>
                              <Typography variant="caption">
                                {question.client_question_submitted_at && formatDateTime(question.client_question_submitted_at)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Card>
                    );
                  })}
                </Stack>
              </Stack>}
              {currentDocument &&
                <Stack>
                  <Typography variant="h6">
                    Compliance Comments
                  </Typography>
                  <Comments />
                </Stack>}
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
}
