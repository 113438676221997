import React from 'react';
import { Outlet } from "react-router-dom";
import { Stack } from '@mui/material';

export default function Root() {
  return (
    <Stack mb={5}>
      <Outlet />
    </Stack>
  )
}