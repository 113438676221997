import { Button, ButtonGroup, Stack, FormGroup, FormControlLabel, Checkbox, Grid2 } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { getApiUrl } from '../../../shared/Network';
import Request from '../../../shared/services/Request';

export default function CheckboxField({ value, field, onChange, handleNext, currentTransaction, documentId, pdfField }) {
  const [ values, setValues ] = React.useState([]);
  const options = field.options;

  console.log(value)

  useEffect(() => {
    Request.get(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/values/${pdfField.id}`, (data) => {
      console.log(data)
      setValues(data.map((v) => v.value))
    })
  }, [])

  const handleCheck = (option) => {
    Request.post(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/values/${pdfField.id}`, {
      value: option.value
    }, (data) => {
      console.log(data)
    })
  }

  const handleUncheck = (option) => {
    Request.delete(`${getApiUrl()}/v2/client_apps/transactions/${currentTransaction?.id}/documents/${documentId}/values/${pdfField.id}/${option.value}`, (data) => {
      console.log(data)
    }, (error) => {
      console.log(error)
    })
  }

  const handleChange = (checked, option) => {
    if (checked) {
      setValues([...values, option.value])
      handleCheck(option)
    } else {
      setValues(values.filter((v) => v !== option.value))
      handleUncheck(option)
    }
  }

  return (
    <Stack aria-label="Basic button group">
      <FormGroup>
        <Grid2 container>
          {
            options.map((option) => (
              <Grid2 size={{ xs: 12, sm: 3}} sx={{ textAlign: "left" }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={values.includes(option.value)} onChange={(e) => handleChange(e.target.checked, option)} name="jason" />
                  }
                  label={option.label}
                />
              </Grid2>
            ))
          }
        </Grid2>
      </FormGroup>
    </Stack>
  )
}