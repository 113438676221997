import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function Settings() {
  return (
    <Stack>
      <Stack>
        <Typography variant="h4">Account management</Typography>
      </Stack>
      <Stack>
        <Typography variant="body1">
          You can manage your account here. This includes changing your password, updating your email address, and other account settings.
        </Typography>
      </Stack>
    </Stack>
  )
} 