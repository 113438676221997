import React from 'react';
import Nav from 'react-bootstrap/Nav';
import SignatureIcon from 'images/icon-signature.svg';
import InitialsIcon from 'images/icon-initials.svg';
import TextIcon from 'images/icon-text.svg';
import CalendarIcon from 'images/icon-calendar.svg';
import CheckboxIcon from 'images/icon-checkbox.svg';
import WhiteSignatureIcon from 'images/icon-signature-white.svg';
import WhiteInitialsIcon from 'images/icon-initials-white.svg';
import WhiteTextIcon from 'images/icon-text-white.svg';
import WhiteCalendarIcon from 'images/icon-calendar-white.svg';
import WhiteCheckboxIcon from 'images/icon-checkbox-white.svg';
import { Button, Stack } from '@mui/material';

export default function Action(props) {
  const style = () => {
    if (props.active) {
      return {
        border: '2px solid #2A6047',
        background: '#2A6047',
        display: 'block',
        marginBottom: '3px',
        color: '#FFF',
        borderRadius: '5px'
      };
    } else {
      return {
        border: '2px solid #2A6047',
        display: 'block',
        marginBottom: '3px',
        color: '#2A6047',
        borderRadius: '5px'
      };
    }
  };

  const getIcon = () => {
    let src = '';
    switch (props.fieldType) {
      case 'signature':
        src = props.active ? WhiteSignatureIcon : SignatureIcon;
        break;
      case 'initials':
        src = props.active ? WhiteInitialsIcon : InitialsIcon;
        break;
      case 'text':
        src = props.active ? WhiteTextIcon : TextIcon;
        break;
      case 'checkbox':
        src = props.active ? WhiteCheckboxIcon : CheckboxIcon;
        break;
      case 'user_checkbox':
        src = props.active ? WhiteCheckboxIcon : CheckboxIcon;
        break;
      case 'date_signed':
        src = props.active ? WhiteCalendarIcon : CalendarIcon;
        break;
    }
    return src;
  };

  return (
    <Nav.Link
      href="#"
      className={'w-100 d-flex align-items-center justify-content-center'}
      style={style()}
      // onClick={() => props.createField(props.fieldType)}
      onClick={() => {
        console.log(
          'Changed action to: ' + props.fieldType + ' active: ' + props.active
        );
        props.changeActiveAction(props.fieldType);
      }}
    >
      <Button
        size="small"
        style={{
          background: 'transparent',
          border: 'none',
          color: 'inherit'
        }}
      >
        <Stack>
          <div>
            <span>
              <img src={getIcon()} />
            </span>
          </div>
          <div style={{ color: 'inherit' }}>{props.label}</div>
        </Stack>
      </Button>
    </Nav.Link>
  );
}
